<template>
  <section :style="style" class="layout-wrapper">
    <slot />
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { SpacingSize } from "@horizon56/styles/types";
import { giveMeArray } from "@horizon56/utils";

type Padding = SpacingSize | [SpacingSize, SpacingSize] | [SpacingSize, SpacingSize, SpacingSize, SpacingSize];
type LayoutWrapperProps = {
  fillWidth?: boolean;
  flow?: "row" | "column" | "row nowrap";
  gap?: SpacingSize;
  alignItems?: "center" | "flex-start" | "flex-end";
  padding?: Padding;
};
const props = withDefaults(defineProps<LayoutWrapperProps>(), { fillWidth: true, flow: "column" });

const mapPadding = (p: Padding) =>
  giveMeArray(p)
    .map((size) => `var(--app-spacing-size-${size})`)
    .join(" ");

const style = computed(
  () => `
  --layout-wrapper-flow: ${props.flow === "row" ? "row wrap" : props.flow};
  --layout-wrapper-width: ${props.fillWidth ? "100%" : "unset"};
  --layout-wrapper-gap: ${props.gap ? `var(--app-spacing-size-${props.gap})` : "0px"};
  --layout-wrapper-padding: ${props.padding ? mapPadding(props.padding) : "0px"};
  --layout-wrapper-align-items: ${props?.alignItems || "unset"}
`,
);
</script>

<style lang="scss" scoped>
.layout-wrapper {
  display: flex;
  width: var(--layout-wrapper-width);
  flex-flow: var(--layout-wrapper-flow, column);
  gap: var(--layout-wrapper-gap, 0px);
  padding: var(--layout-wrapper-padding, 0px);
  align-items: var(--layout-wrapper-align-items, unset);
}
</style>
